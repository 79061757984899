<template>
    <div class="screen1 page">
        <div class="nav-box flex-between">
            <img class="logo" src="../assets/images/image1.png" alt="">
            <div class="nav-btn-box flex-between">
                <div class="nav-btn">
                    <a href="https://www.baidu.com/" target="_blank"><span>开源项目</span></a>
                    <a href="https://www.baidu.com/" target="_blank"><span>文档中心</span></a>
                    <a href="https://www.baidu.com/" target="_blank"><span>价格方案</span></a>

                    <!-- <span @click="handlePrice">价格方案</span> -->
                    <span @click="handleCode">购买咨询</span>
                    <a href="https://www.baidu.com/" target="_blank"><span>Start支持项目目</span></a>
                    <span class="qrCode" @mouseover="onMouseOverT" @mouseleave="onMouseOutT">体验小程序<div class="codeBg"
                            v-if="isShow">
                            <img src="../assets/images/qrcode1.png" />
                        </div>
                    </span>
                </div>
                <a-button class="nav-login" type="primary" danger>登录</a-button>
            </div>
        </div>
        <div class="screen1-content flex-between">
            <div class="content-box">
                <div class="title">快递cps代下系统</div>
                <div class="tips flex">
                    <img src="../assets/images/Vector2.png" alt="">
                    <div>聚合全网寄件平台渠道，覆盖所有快递物流公司</div>
                </div>
                <div class="tips flex">
                    <img src="../assets/images/Vector2.png" alt="">
                    <div>AI自动多平台渠道比价，实现更低的成本</div>
                </div>
                <div class="tips flex">
                    <img src="../assets/images/Vector2.png" alt="">
                    <div>最优的价格，私域多重变现首选</div>
                </div>
                <a-button class="used-btn" type="primary" danger @click="handleCode">开始使用</a-button>
            </div>
            <img class="img-index" src="../assets/images/index.png" alt="">
        </div>
    </div>
    <div class="screen2">
        <div class="title-box flex-col-center">
            <div class="line"></div>
            <div class="title">系统功能概述</div>
            <div class="sub-title">简单概括淘客系统的功能与优势</div>
        </div>
        <div class="content-box">
            <div class="content flex-col-center">
                <img src="../assets/images/Frame4.png" alt="">
                <div class="conetent-title">优势</div>
                <div class="content-text">以技术为核心，以结果为导向，对运营负责，为您打造专业、高效、优质、高贴合度的私域变现工具</div>
            </div>
            <div class="content flex-col-center">
                <img src="../assets/images/Frame1.png" alt="">
                <div class="conetent-title">系统功能齐全</div>
                <div class="content-text">支持微信公众号和微信小程序下单，操作简单，便捷易用，快递、同城急送、大件物流均可轻松搞定</div>
            </div>
            <div class="content flex-col-center">
                <img src="../assets/images/Frame2.png" alt="">
                <div class="conetent-title">营销玩法多样</div>
                <div class="content-text">无门槛优惠券、满减优惠券、分销板块，助您更好的营销、留存用户，把品牌做大，盈利更简单！</div>
            </div>
            <div class="content flex-col-center">
                <img src="../assets/images/Frame7.png" alt="">
                <div class="conetent-title">智能AI比价</div>
                <div class="content-text">云洋、易达、包包达、Q必达等快递平台全部聚合，智能AI比价，寄件成本价做到最低</div>
            </div>
            <div class="content flex-col-center">
                <img src="../assets/images/Frame5.png" alt="">
                <div class="conetent-title">系统稳定安全</div>
                <div class="content-text">系统独立部署，稳定可靠，数据自主掌控，安全放心，不再做别人的嫁衣！</div>
            </div>
            <div class="content flex-col-center">
                <img src="../assets/images/Frame6.png" alt="">
                <div class="conetent-title">高并发高性能</div>
                <div class="content-text">系统并发能力强劲，坚持以用户为中心，为用户提供高效率高质量的使用体验。</div>
            </div>
        </div>
    </div>
    <div class="screen3">
        <div class="title-box flex-col-center">
            <div class="line"></div>
            <div class="title">聚合多家快递平台</div>
            <div class="sub-title">便宜｜快捷｜安全｜多样</div>
        </div>
        <div class="delivery-box">

            <div class="delivery flex-col-center">
                <img src="../assets/images/shunfeng.png" alt="">
                <div>顺丰快递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/debang.png" alt="">
                <div>德邦快递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/jingdong.png" alt="">
                <div>京东物流</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/yuantong.png" alt="">
                <div>圆通速递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/shentong.png" alt="">
                <div>申通快递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/zhongtong.png" alt="">
                <div>中通快递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/yunda.png" alt="">
                <div>韵达快递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/jitu.png" alt="">
                <div>极兔速递</div>
            </div>

            <div class="delivery flex-col-center">
                <img src="../assets/images/shansong.png" alt="">
                <div>闪送</div>
            </div>
        </div>
    </div>
    <div class="screen4">
        <div class="title-box flex-col-center">
            <div class="line"></div>
            <div class="title">平台优势</div>
            <div class="sub-title">简单概括淘客系统的功能与优势</div>
        </div>
        <div class="advantage-box flex-between">
            <div class="advantage flex-col-center">
                <img src="../assets/images/Vector4.png" alt="">
                <div class="title">优势一</div>
                <div class="tips">完善的分销裂变模块，用户申请成为分销推广员，并享受推广分佣权利，助力解决平台流量增长难题</div>
            </div>
            <div class="advantage flex-col-center">
                <img src="../assets/images/Frame3.png" alt="">
                <div class="title">优势二</div>
                <div class="tips">长期项目，用户市场广阔，快递CPS系统支持私有化部署到你的服务器中， <br /> 用户数据、订单数据专属于你是最重要的，我们持续盈利的基石。</div>
            </div>
            <div class="advantage flex-col-center">
                <img src="../assets/images/Group3.png" alt="">
                <div class="title">优势三</div>
                <div class="tips">耕耘未来团队自主研发，拥有整套快递CPS系统的软件著作权，让您使用更有保障</div>
            </div>
        </div>
    </div>
    <div class="screen5" id="price">
        <div class="system flex-between">
            <div class="left">
                <div class="title">购买CPS系统</div>
                <div class="tips">有价值的东西只有对懂得的人才有意义</div>
            </div>
            <div class="right flex" @click="handleCode">
                <div>了解更多</div>
                <img src="../assets/images/Vector3.png" alt="">
            </div>
        </div>
        <div class="package-box flex-between">
            <div class="package">
                <div class="flex-end">
                    <div>
                        <div class="title">基础套餐</div>
                        <div class="price">¥8999<span>/年</span></div>
                    </div>
                    <a-button class="pay-btn" type="primary" danger @click="handleCode">立即购买</a-button>
                </div>
                <div class="content">
                    <p>个人/公司需要快递cps项目做多重变现或者做创业，我们会将程序安装包私有化部署到你的服务器，专业导师一对一指导做快递cps项目 </p>
                    <p>一年期免费更新程序，次年续费8折</p>
                    <div class="flex img-box">
                        <img src="../assets/images/system.png" alt="">
                        <div>使用范围: 1授权码 1机器</div>
                    </div>
                </div>
            </div>
            <div class="package">
                <div class="flex-end" style=" background: #5986F5;">
                    <div>
                        <div class="title" style="color:#FFF">专业套餐</div>
                        <div class="price" style="color:#FFF">¥29999<span style="font-size:10px">/永久</span></div>
                    </div>
                    <a-button class="pay-btn" type="primary" danger @click="handleCode">立即购买</a-button>
                </div>
                <div class="content">
                    <p>单位内需要进行快递cps项目做多重变现，需要修改部分功能，我们将交付100%源码给你 </p>
                    <p>6个月免费工单支持，提供部署文档、使用手册</p>
                    <div class="flex img-box">
                        <img src="../assets/images/system.png" alt="">
                        <div>使用范围: 仅限购买单位自己内部项目、系统使用</div>
                    </div>
                </div>
            </div>
            <div class="package">
                <div class="flex-end">
                    <div>
                        <div class="title">商用套餐</div>
                        <div class="case" @click="handleCode">获取您的商用方案<img src="../assets/images/Vector3.png" /></div>
                    </div>
                </div>
                <div class="content">
                    <p>适用需要集成快递cps系统到单位旗下app、系统、项目中，还需要定制开发一些功能 </p>
                    <p>或者单纯需要定制开发，都可以联系我们，我们给你定制解决方案</p>
                    <div class="flex img-box">
                        <img src="../assets/images/system.png" alt="">
                        <div>使用范围: 可二开后作为闭源产品发售，做商业化</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="screen6">
        <div class="title-box flex-col-center">
            <div class="line"></div>
            <div class="title">购买流程</div>
            <div class="sub-title">流程简单清晰，快速响应需求；早购买早优惠！</div>
        </div>
        <div class="buy flex-between">
            <div class="buy-step">
                <img src="../assets/images/image3.png" />
                <div class="title">联系商务人员</div>
                <div class="tips">首先使用微信扫一扫添加商务人员进行沟通</div>
            </div>
            <img class="buy-next" src="../assets/images/next.png" />
            <div class="buy-step">
                <img src="../assets/images/image3.png" />
                <div class="title">完成支付</div>
                <div class="tips">支持对公打款、微信支付、支付宝等付款渠道</div>
            </div>
            <img class="buy-next" src="../assets/images/next.png" />
            <div class="buy-step">
                <img src="../assets/images/image3.png" />
                <div class="title">信息填写</div>
                <div class="tips">合同签订、授权信息填写、开票信息填写</div>
            </div>
            <img class="buy-next" src="../assets/images/next.png" />
            <div class="buy-step">
                <img src="../assets/images/image3.png" />
                <div class="title">系统搭建、代码交付</div>
                <div class="tips">我们将会有专属人员进行系统搭建、系统培训+项目陪跑</div>
            </div>
        </div>
        <div class="cooperate flex-between">
            <div class="left">
                <div class="title">合作 / 代理招募中</div>
                <div class="tips">
                    <img src="../assets/images/Vector1.png" />
                    <span>如果你有公众号小程序 App 等流量渠道入口，也可以联系我们对接一起合作流量变现，全网最高返佣金60%</span>
                </div>
                <div class="tips">
                    <img src="../assets/images/Vector1.png" />
                    <span>如果你正在寻找副业项目，也可以联系我们来做快递代理这个副业项目，一起帮助大家省钱寄快递，不花冤枉钱</span>
                </div>
                <a-button class="btn" type="primary" danger @click="handleCode">联系客服</a-button>
            </div>
            <img class="cooperate-img" src="../assets/images/it-support-monochromatic.png" />
        </div>

    </div>
    <div class="screen7">
        <div class="data-box flex-between">
            <div class="data">
                <div class="title">日下单量</div>
                <div class="number">36378<span>件+</span></div>
            </div>
            <div class="data">
                <div class="title">日下单金额</div>
                <div class="number">453527<span>元+</span></div>
            </div>
            <div class="data">
                <div class="title">活跃用户</div>
                <div class="number">578532<span>个+</span></div>
            </div>
            <div class="data">
                <div class="title">会员总数</div>
                <div class="number">7334680<span>个+</span></div>
            </div>
        </div>
        <div class="title-box flex-col-center">
            <div class="line"></div>
            <div class="title">企业资质</div>
            <div class="sub-title">企业携手和谐发展，共同进步</div>
        </div>
        <div class="qualifications flex-center">
            <img src="../assets/images/Rectangle1.png" />
            <img src="../assets/images/Rectangle2.png" />
        </div>
    </div>
    <div class="screen8">
        <div class="title-box flex-col-center">
            <div class="line"></div>
            <div class="title">平台合作伙伴</div>
            <div class="sub-title">企业携手和谐发展，共同进步</div>
        </div>
        <div class="flex-between">
            <img src="../assets/images/sf.png" />
            <img src="../assets/images/jd.png" />
            <img src="../assets/images/yt.png" />
            <img src="../assets/images/db.png" />
            <img src="../assets/images/jt.jpg" />
            <img src="../assets/images/yd.png" />
            <img src="../assets/images/st.png" />
            <img src="../assets/images/zt.png" />
        </div>
    </div>
    <div class="footer ">
        <div class="flex-between">
            <div class="footer1">
<!--                <img src="../assets/images/image2.png" alt="">-->
            </div>
            <div class="title">
                <h2>产品</h2>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>Link-社区版</p>
                </a>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>Link-商业版</p>
                </a>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>FAQ/部署文档</p>
                </a>
            </div>
            <div class="title">
                <h2>关于</h2>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>人人寄</p>
                </a>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>使用条款</p>
                </a>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>免责声明</p>
                </a>
            </div>
            <div class="title">
                <h2>联系我们</h2>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>咨询作者</p>
                </a>
                <a href="https://www.baidu.com/" target="_blank">
                    <p>bd@cpskd.cn</p>
                </a>
            </div>
            <div class="footer-qrcode">
                <img src="../assets/images/qrcode2.png" alt="">
            </div>
        </div>
      <div class="line"></div>
      <div class="footer-text">Copyright © 2023 - 2024 kdcps. 湖南耕耘未来科技有限公司-
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023025772号-3 </a>All Rights
        Reserved.耕耘未来科技版权所有
      </div>
    </div>
    <div class="contact">
        <div class="flex-col-center contact-card">
            <img class="customer-img" src="../assets/images/customer.png" alt="">
            <div class="flex-center contact-phone">
                <img src="../assets/images/phone.png" alt="">
                <span>电话咨询</span>
            </div>
            <p>15116476625</p>
            <div class="flex-center contact-phone">
                <img src="../assets/images/wx.png" alt="">
                <span>微信扫码</span>
            </div>
            <div class="qrcode">
                <img src="../assets/images/qrcode2.png" alt="">
            </div>
        </div>
        <div class="flex-center goTop" @click="goTop" v-if="showBtn">
            <img src="../assets/images/top.png" alt="">
            <span>回到顶部</span>
        </div>
    </div>
    <a-modal v-model:visible="visible" width="300px" :footer="false">
        <div style="font-size:13px">打开微信 扫描二维码立即开始咨询</div>
        <div class="qrcode" style="margin-top:20px;">
            <img src="../assets/images/qrcode9.png" alt="">
        </div>
    </a-modal>
</template>

<script setup>
import { ref, watch } from 'vue'
const visible = ref(false)

const isShow = ref(false)
const flg = ref(true)
const showBtn = ref(false)
let scrollTopNum = ref('')

const handlePrice = () => {
    document.querySelector("#price").scrollIntoView(true);
}
const handleCode = () => {
    visible.value = true
}
// 鼠标移入1
const onMouseOverT = () => {
    isShow.value = true
}
// 鼠标移出1
const onMouseOutT = () => {
    setTimeout(() => {
        if (flg.value) {
            isShow.value = false
        }
    }, 1)
}
const goTop = () => {
    window.scrollTo({
        top: 0, //回到顶部
        left: 0,
        behavior: "smooth", //smooth 平滑；auto:瞬间
    });
}
watch(scrollTopNum, (newValue, oldValue) => {
    if (newValue > 100) {
        showBtn.value = true
    } else {
        showBtn.value = false

    }
})
const handleScroll = () => {
    let top =
        document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
    scrollTopNum.value = top;
}
window.addEventListener("scroll", handleScroll, true);
</script>
<style scoped lang="scss">
a {
    font-family: font;
    text-decoration: none;
    color: inherit;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #b9b9b9;
  margin: 30px 0;
}

.footer-text {
  font-size: 12px;
  text-align: center;
  color: #f8f8f8;
}

.contact {
    position: fixed;
    right: 20px;
    bottom: 5%;

    .goTop {
        margin-top: 10px;
        background: #3A73FF;
        width: 140px;
        height: 50px;
        border-radius: 10px;
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
            cursor: pointer;

        }

        span {
            font-size: 16px;
            color: #FFFFFF;
            margin-left: 4px;
            cursor: pointer;

        }
    }

    .contact-card {
        width: 140px;
        height: 360px;
        background: #3A73FF;
        box-shadow: 0px 65px 80px -46px rgba(226, 238, 249, 0.6);
        border-radius: 10px;
        padding: 18px 25px;

        .customer-img {
            width: 66px;
            height: 66px;
        }

        .qrcode {
            background-color: #fff;
            padding: 6px;
            border-radius: 4px;

            img {
                width: 104px;
                height: 104px;
            }
        }

        .contact-phone {
            margin: 10px 0;

            img {
                width: 18px;
                height: 18px;
            }

            span {
                font-size: 16px;

                color: #FFFFFF;
                margin-left: 4px;
            }
        }

        p {
            color: #fff;
        }
    }
}

.screen1 {
    background: #E6F0FF;
    width: 100%;

    .screen1-content {
        .content-box {
            .title {
                font-size: 50px;
                font-weight: bold;
                color: #000336;
                line-height: 58px;
                margin-bottom: 32px;
            }

            .used-btn {
                width: 170px;
                height: 50px;
                background: #0F1B4C;
                border-radius: 12px;
                margin-top: 60px;
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
            }

            .tips {
                margin-top: 20px;

                img {
                    width: 9.5px;
                    height: 12.5px;
                }

                div {
                    font-size: 18px;
                    color: #5A6473;
                    margin-left: 12px;
                }
            }
        }

        .img-index {
            width: 786px;
            height: 596px;
            margin-right: -140px;
        }
    }

    .nav-box {
        padding-top: 40px;

        .logo {
            width: 120px;
            // height: 33px;
        }

        .nav-btn-box {
            .nav-login {
                width: 98px;
                height: 40px;
                background: #0F1B4C;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 16px;
            }

            .nav-btn {
                .qrCode {
                    position: relative;

                    .codeBg {
                        width: 230px;
                        height: 260px;
                        position: absolute;
                        top: 0;
                        left: -78px;
                        background: url('../assets/images/codeBg.png');
                        background-size: 100% 100%;

                        img {
                            width: 113px;
                            height: 113px;
                            margin: auto;
                            display: block;
                            margin-top: 72px;
                        }
                    }
                }

                span {
                    margin-right: 40px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #4F5361;
                    cursor: pointer;
                }
            }
        }
    }
}

.screen2 {
    padding: 80px 165px;

    .content-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 60px;

        .content {
            width: 30%;
            margin-top: 40px;

            img {
                width: 46px;
                height: 46px;
            }

            .conetent-title {
                font-size: 20px;
                font-weight: 500;
                color: #3B3C45;
                line-height: 22px;
                margin-top: 30px;
            }

            .content-text {
                width: 230px;
                font-size: 14px;
                color: #5A6473;
                line-height: 24px;
                margin-top: 12px;
                text-align: center;
            }
        }
    }
}

.screen3 {
    background: #F5FAFE;
    padding: 70px 165px 120px 165px;

    .delivery-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        .delivery {
            width: 18%;
            height: 197px;
            background: #FFFFFF;
            border-radius: 20px 20px 20px 20px;
            margin-top: 30px;
            margin: 30px 1% 0;

            img {
                width: 132px;
                height: 94px;
            }

            div {
                margin-top: 20px;
                font-size: 16px;
                color: #000339;
            }
        }
    }
}

.screen4 {
    padding: 80px 165px;

    .advantage-box {
        margin-top: 70px;

        .advantage:nth-child(2) {
            background: #FFFFFF;
            box-shadow: 0px 50px 70px 0px rgba(162, 182, 212, 0.16);
            border-radius: 0px 0px 0px 0px;
        }

        .advantage {
            width: 33%;
            padding: 30px;
            box-sizing: border-box;

            img {
                width: 80px;
                height: 80px;
            }

            .title {
                font-size: 28px;
                font-weight: 500;
                color: #474F62;
                line-height: 38px;
                margin: 40px 0 20px 0;
            }

            .tips {
                font-size: 14px;
                color: #474F62;
                line-height: 26px;
                text-align: center;
            }
        }
    }
}

.screen5 {
    background: #F5FAFE;
    padding: 70px 165px 120px 165px;

    .package-box {
        margin-top: 70px;

        .package {
            width: 32%;

            .case {
                margin-top: 30px;
                font-size: 22px;
                font-weight: 500;
                color: #5784F5;
                cursor: pointer;

                img {
                    width: 24px;
                    height: 8px;
                    margin-left: 6px;
                }
            }

            .content {
                width: 100%;
                height: 220px;
                background: #fff;
                padding: 25px;
                box-sizing: border-box;
                border-radius: 0 0 12px 12px;

                .flex-between {
                    margin-top: 30px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 500;
                    color: #333;
                    font-family: font;
                }

                .img-box {
                    img {
                        width: 18px;
                        height: 18px;
                    }

                    div {
                        font-size: 14px;
                        color: #0688FF;
                        margin-left: 10px;
                    }
                }
            }

            .flex-end {
                width: 100%;
                height: 176px;
                background: #E6F0FF;
                padding: 38px 25px;
                box-sizing: border-box;
                border-radius: 12px 12px 0 0;
            }

            .title {
                font-size: 28px;
                font-weight: 500;
                color: #17275F;
            }

            .price {
                font-size: 32px;
                font-weight: 600;
                color: #5784F5;
                margin-top: 30px;

                span {
                    font-size: 14px;

                }
            }

            .pay-btn {
                width: 88px;
                height: 36px;
                background: #F7A804;
                border-radius: 6px 6px 6px 6px;
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }

    .system {
        align-items: flex-end;

        .left {
            .title {
                font-size: 32px;
                font-weight: 500;
                color: #000339;
                line-height: 35px;
            }

            .tips {
                font-size: 16px;
                font-weight: 500;
                color: #5A6473;
                line-height: 30px;
                margin-top: 15px;
            }
        }

        .right {
            cursor: pointer;

            div {
                font-size: 18px;

                font-weight: 500;
                color: #0689FF;
                line-height: 21px;
            }

            img {
                width: 24px;
                height: 8px;
                margin-left: 8px;
            }
        }
    }
}

.screen6 {
    padding: 80px 165px;

    .cooperate {
        background: #0F1B4C;
        border-radius: 20px;
        padding: 25px 100px;
        box-sizing: border-box;
        margin-top: 80px;

        .left {
            .title {
                font-size: 32px;
                font-weight: 500;
                color: #FFFFFF;
            }

            .tips {
                margin-top: 16px;

                img {
                    width: 9.5px;
                    height: 12.5px;
                    margin-right: 9px;
                }

                span {
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 30px;
                    opacity: .8;
                }
            }

            .btn {
                width: 151px;
                height: 50px;
                background: #FFFFFF;
                border-radius: 12px;
                font-size: 14px;
                font-weight: 500;
                color: #002366;
                margin-top: 40px;
            }
        }

        .cooperate-img {
            width: 406px;
            height: 320px;
            margin-left: 80px;
        }
    }

    .buy {
        margin-top: 72px;

        .buy-step {
            img {
                width: 32px;
                height: 35px;
            }

            .title {
                font-size: 20px;
                font-weight: 500;
                color: #000339;
                margin: 30px 0 20px 0;

            }

            .tips {
                font-size: 16px;
                font-weight: 500;
                color: #5F5F64;
                max-width: 200px;
                line-height: 26px;
            }
        }

        .buy-next {
            width: 32px;
            height: 32px;
            margin: 120px 30px 15px;
        }
    }
}

.screen7 {
    background: #F5FAFE;
    padding: 70px 165px 120px 165px;

    .qualifications {
        margin-top: 50px;

        img {
            width: 297px;
            height: 398px;
            margin-left: 30px;
        }
    }

    .data-box {
        margin-bottom: 120px;

        .data {
            .title {
                font-size: 18px;
                font-weight: 500;
                color: #7B8087;
            }

            .number {
                font-size: 50px;
                font-weight: 600;
                color: #000000;
                margin-top: 16px;

                span {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }
}

.footer {
    background: #18224F;
    padding: 50px 165px;

    .flex-between {
        align-items: flex-start;

    }

    .footer1 {

        img {
            width: 120px;
            // height: 33px;
        }

        div {
            font-size: 14px;
            color: #C2C2C2;
        }
    }

    .footer-qrcode {
        background-color: #fff;
        padding: 10px;
        border-radius: 6px;

        img {
            width: 120px;
            height: 120px;
        }
    }

    .title {
        // width: 25%;

        h2 {
            margin-bottom: 40px;
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;
        }

        p {
            font-size: 16px;
            color: #D3D3D3;
        }

        .Facebook {
            img {
                width: 28px;
                height: 28px;
                margin-right: 16px;
            }
        }
    }
}

.screen8 {
    padding: 80px 165px;

    .flex-between {
        margin-top: 20px;

        .center-img {
            width: 204px;
            height: 91px;
            background: #FFFFFF;
            box-shadow: 0px 45px 80px 0px rgba(0, 0, 0, 0.04);
            border-radius: 12px;

            img {
                display: block;
                margin: 31px auto;
            }
        }

        img {
            max-width: 120px;
        }
    }
}

.title-box {
    .line {
        width: 41px;
        height: 4px;
        background: #000339;
        border-radius: 12px;
    }

    .title {
        font-size: 34px;
        font-weight: 500;
        color: #000339;
        line-height: 35px;
        margin: 20px 0;
    }

    .sub-title {
        font-size: 16px;
        color: #5A6473;
        line-height: 30px;
    }
}

.page {
    padding: 0 165px;
}

.flex {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    font-family: font;
}
</style>
